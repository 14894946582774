import React, { useEffect, useState } from "react"

import { Date, MonthDate, isPastDate, toMonth, parseDate } from "./Date"

const EventSection = ({ children, id, title }) => (
  <div className="content markdown">
    <h2>
      <a className="anchor" href={`#${id}`} id={id}>
        {title}
      </a>
    </h2>
    <hr />
    {children}
  </div>
)

const TimelineWrapper = ({ event, showMonth, children }) => {
  const date = event.frontmatter.date

  const [isPast, setIsPast] = useState(isPastDate(event.frontmatter.date))

  useEffect(() => {
    setIsPast(isPastDate(date))
  }, [date])

  const pastClass = isPast ? "past" : ""
  const imageClass = event.frontmatter.promoImage ? "has-image" : "no-image"
  const monthKey = "events-" + toMonth(date)

  return (
    <div key={monthKey} className={["month", pastClass, imageClass].join(" ")}>
      {showMonth && (
        <h4>
          <a className="anchor" href={`#${monthKey}`} id={monthKey}>
            <MonthDate>{date}</MonthDate>
          </a>
        </h4>
      )}
      {children}
    </div>
  )
}

function byDate(event1, event2) {
  return event1.frontmatter.date.localeCompare(event2.frontmatter.date)
}

function byDateDesc(event1, event2) {
  return event2.frontmatter.date.localeCompare(event1.frontmatter.date)
}

function isEqualToYear(year) {
  return (event) => {
    const date = parseDate(event.frontmatter.date)
    return date.format("YYYY") === `${year}`
  }
}

const MonthlyEvents = ({ events, desc = true }) => {
  const years = [...new Set(events.map((e) => parseDate(e.frontmatter.date).format("YYYY")))].sort(
    (a, b) => b.localeCompare(a)
  )
  const byYear = years.map((year) => {
    return {
      year: year,
      events: events.filter(isEqualToYear(year)).sort(desc ? byDateDesc : byDate),
    }
  })

  const shouldShowMonth = (event, previousEvent) => {
    const currentMonth = parseDate(event.frontmatter.date).format("MM")
    const previousMonth = previousEvent && parseDate(previousEvent.frontmatter.date).format("MM")
    return currentMonth !== previousMonth
  }

  return (
    <>
      {events.length === 0 && <p>Nenhum evento</p>}
      {byYear.map((collection) => {
        return (
          <>
            <h3>{collection.year}</h3>
            {collection.events.map((event, index) => (
              <TimelineWrapper
                event={event}
                showMonth={shouldShowMonth(event, collection.events[index - 1])}>
                <SingleEvent event={event} />
              </TimelineWrapper>
            ))}
          </>
        )
      })}
    </>
  )
}

const YoutubePreview = ({ url, title }) => {
  const EMBED_BASE_URL = "https://www.youtube.com/embed"
  let videoId = null

  if (url.startsWith("https://youtu.be")) {
    // assumes the short share URL (i.e. https://youtu.be/jw984112XFI)
    videoId = url.replace(/.*\//, "")
  }

  if (url.indexOf("/watch?v=") > 0) {
    // assumes youtube main URL (i.e. https://www.youtube.com/watch?v=efjFRH1Y2nE)
    videoId = url.replace(/.*\/watch\?v=/, "")
  }

  if (!url.startsWith(EMBED_BASE_URL)) {
    url = `${EMBED_BASE_URL}/${videoId}`
  }

  return (
    <iframe
      style={{ float: "right" }}
      className="youtube-iframe"
      src={url}
      title={title}
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  )
}

const SingleEvent = ({ event }) => {
  const isPast = isPastDate(event.frontmatter.date)

  return (
    <>
      <div className="event" style={{ minHeight: "300px" }}>
        <h5 className="mt-2">
          <a href={event.fields.slug}>{event.frontmatter.title}</a>
        </h5>
        <div className="img-wrapper mb-2 bt-1 ml-4">
          {event.frontmatter.youtubeURL && (
            <YoutubePreview url={event.frontmatter.youtubeURL} title={event.frontmatter.title} />
          )}
          {!event.frontmatter.youtubeURL && event.frontmatter.promoImage && (
            <img className="promo-image promo-image-mobile" src={event.frontmatter.promoImage} />
          )}
        </div>
        <p className="mt-2">{event.frontmatter.description}</p>
        <ul>
          {event.frontmatter.notes.map((note) => (
            <li key={note}>{note}</li>
          ))}
          {event.frontmatter.link && (
            <li>
              <a href={event.frontmatter.link} target="_blank" rel="noreferrer">
                <b>{event.frontmatter.linkText}</b>
              </a>
            </li>
          )}
        </ul>
        {event.frontmatter.confirmed && (
          <p>
            <b>
              {event.frontmatter.dateOverride ? (
                event.frontmatter.dateOverride
              ) : (
                <Date>{event.frontmatter.date}</Date>
              )}
            </b>
            {event.frontmatter.endTime &&
              event.frontmatter.startTime &&
              ` das ${event.frontmatter.startTime} às ${event.frontmatter.endTime}`}
            {!event.frontmatter.endTime &&
              event.frontmatter.startTime &&
              ` às ${event.frontmatter.startTime}`}
          </p>
        )}
        {!isPast && event.frontmatter.form && (
          <p>
            <a href={event.frontmatter.form} target="_blank" rel="noreferrer">
              <b>Inscreva-se já!</b>
            </a>
          </p>
        )}
        {event.html && <div dangerouslySetInnerHTML={{ __html: event.html }} />}
        <br />
        <br />
      </div>
    </>
  )
}

const WeeklyEvent = ({ event }) => {
  return (
    <div>
      <h3>
        <a href={event.fields.slug}>{event.frontmatter.title}</a>
      </h3>
      {event.frontmatter.promoImage && (
        <img style={{ float: "right", width: "300px" }} src={event.frontmatter.promoImage} />
      )}
      {event.frontmatter.description}
      <ul>
        <li>
          <b>Horário:</b> {event.frontmatter.when}
        </li>
        <li>
          <a href={event.frontmatter.link} target="_blank" rel="noreferrer">
            <b>{event.frontmatter.linkText}</b>
          </a>
        </li>
        {event.frontmatter.notes.map((note) => (
          <li key={note}>
            <b>{note}</b>
          </li>
        ))}
      </ul>
      <br />
      <br />
    </div>
  )
}

const NextEvent = ({ event }) => {
  return (
    <div className="nextEvent">
      <div className="container flex">
        <div className="grow p-6">
          {event.frontmatter.confirmed && <h2 className="is-size-4">Inscrições abertas!</h2>}

          <h3 className="has-text-weight-bold is-size-3">{event.frontmatter.title}</h3>

          <p className="py-5 is-size-4">
            <b>
              {event.frontmatter.dateOverride ? (
                event.frontmatter.dateOverride
              ) : (
                <Date>{event.frontmatter.date}</Date>
              )}
            </b>
          </p>

          <a className="link is-size-5" href={event.fields.slug}>
            Mais informações...
          </a>
        </div>
      </div>
    </div>
  )
}


export { EventSection, SingleEvent, MonthlyEvents, WeeklyEvent, NextEvent }
